'use strict';

let $ = window.$ = window.jQuery = require('jquery');
require('slick-carousel');
require('jquery-colorbox');

let min_tablet = 600;
let min_large_tablet = 900;
let min_desktop = 1200;

$(function() {
    $('#nav-mobile > .hamburger').on('click', function () {
        $(this).parent().toggleClass('open');

        return false;
    });

    $('#nav-mobile > .menu > ul > li > .hover > a').on('click', function () {
        if ($(this).hasClass('open') || $(this).next('.menu').length == 0) {
            return true;
        }

        $(this).closest('ul').find('> li > .hover > a').removeClass('open');
        $(this).addClass('open');

        return false;
    });

    $(document).on('click', '.maps a.colorbox', function () {
        $(document.body).addClass('colorboxing');

        $.colorbox({
            href: $(this).attr('href'),
            maxWidth: '100%',
            maxHeight: '100%',
        });

        return false;
    });

    $(document).on('cbox_cleanup', function () {
        $(document.body).removeClass('colorboxing');
    });

    $('.widget.testimonials a.readmore').on('click', function () {
        $(this).hide();
        $(this).next('.body').show();

        return false;
    });

    $('.block-news a.readmore').on('click', function () {
        $(this).closest('.tease-post').addClass('open');

        return false;
    });

    let updateFilters = function () {
        let opts = $('.widget.workshops input, #cboxLoadedContent > .filters input').serialize();

        if (opts.length) {
            window.location.search = '?' + opts;
        } else {
            window.location.search = '';
        }
    };

    $('.widget.workshops input').on('change', function () {
        if ($(window).width() >= min_tablet) {
            updateFilters();
            return;
        }

        if ($(this).closest('.widget.workshops > .maincats').length) {
            updateFilters();
            return;
        }
    });

    $('.widget.workshops .openFilters').on('click', function () {
        $(document.body).addClass('colorboxing');

        $.colorbox({
            maxWidth: '100%',
            maxHeight: '100%',
            inline: true,
            href: '.widget.workshops .filters',
        });

        return false;
    });

    $('.widget.workshops .filters .close').on('click', $.colorbox.close);

    $('.widget.workshops .filters .search').on('click', function () {
        updateFilters();
    });
    
    $('.side-pic-container .slider').slick({
        draggable: false,
        dots: false,
        autoplay: false,
        adaptiveHeight: true,
        autoplaySpeed: 5000,
        fade: true,
    });

    
    var historyState;
    
    function colorBoxClose(){
       
        if (window.location.hash.substr(0,4) == '#360' && historyState == '360click'){
                historyState = '';
                window.history.back();
        }
        
    }
    
    
    $('.side-pic-container .slide.threesixty').click(function(){
        console.log($(this).data('image'));
        $(document.body).addClass('colorboxing');

        let w = $(window).width();
        let h = $(window).height();

        if (w >= 700) {
            w *= 0.9;
            h *= 0.9;
        }

        if (w / 16 * 9 < h) {
            h = w / 16 * 9;
        } else {
            w = h / 9 * 16;
        }

        let html = '<iframe width="100%" height="100%" allowfullscreen style="border-style:none;" src="https://cdn.pannellum.org/2.4/pannellum.htm#panorama='+$(this).data('image')+'&autoLoad=true"></iframe>';
         
        $.colorbox({
            html: html,
            width: w | 0,
            height: h | 0,
            onClosed: colorBoxClose,            
        });
        
        window.location.hash = '360';
        historyState = '360click';
    });
    
    $(window).on('hashchange', function() {
        if (window.location.hash == ''){
            $('#cboxOverlay').click();
        }
    });
    
    
});
